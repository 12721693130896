import React, { useEffect, useState } from 'react';

// Componente BottomSheetModal
const BottomSheetModal = ({ isVisible, onClose, children, height = '45vh' }) => {
  // Estado para controlar a visibilidade real do modal (para animações)
  const [showModal, setShowModal] = useState(false);
  // Estado para controlar o processo de animação
  const [isAnimating, setIsAnimating] = useState(false);

  // Atualiza o estado de visibilidade e animação quando isVisible muda
  useEffect(() => {
    if (isVisible) {
      setShowModal(true);
      // Usamos um pequeno timeout para garantir que a animação de abertura seja aplicada corretamente
      setTimeout(() => setIsAnimating(true), 10);
    } else {
      setIsAnimating(false);
      // Após a animação de fechamento, removemos o modal do DOM
      setTimeout(() => setShowModal(false), 300); // Deve coincidir com a duração da transição
    }
  }, [isVisible]);

  // Estilos básicos para o modal
  const modalStyles = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    transition: 'transform 0.3s ease-in-out',
    backgroundColor: '#28313e',
    borderTopLeftRadius: '16px',
    borderTopRightRadius: '16px',
    boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.2)',
    overflowY: 'auto',
    transform: isAnimating ? 'translateY(0)' : 'translateY(100%)', // Controla a animação de slide
    height,
  };

  // Estilos para o overlay (fundo escuro)
  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
    opacity: isAnimating ? 1 : 0, // Animação de fade-in e fade-out
    transition: 'opacity 0.3s ease-in-out',
  };

  // Estilos para o traço no topo
  const dragHandleStyles = {
    width: '50px',
    height: '5px',
    backgroundColor: '#ccc',
    borderRadius: '5px',
    margin: '10px auto',
    cursor: 'pointer',
  };

  // Fecha o modal quando o overlay é clicado
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <>
      {showModal && (
        <>
          {/* Overlay que escurece o fundo */}
          <div style={overlayStyles} onClick={handleOverlayClick} />

          {/* Modal que desliza de baixo para cima */}
          <div style={modalStyles}>
            {/* Traço no topo (indicador de arraste) */}
            <div style={dragHandleStyles} onClick={onClose}></div>

            {/* Conteúdo passado via props.children */}
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default BottomSheetModal;
