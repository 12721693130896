import react from "react";

const Select = ({ label = "", value = "Selecione uma opção", options = [], className = "form-control form-input", onChangeOption, errors = [], variant= "normal", ...props }) => {
    let randForId = "fild-" + Math.random();


    const hasError = !errors[props.name] ? "" : "is-invalid"

    const ErrorDialog = () => {
        return !errors[props.name] ? <></> :
         (
           <div className="invalid-feedback">
               {errors[props.name][0]}
           </div>
        )
    }


    const mapOptions = () => {
        return (
            options.map((item, index) => {
                return (
                    <option key={index}  value={item.value}    >{item.label}</option>
                )
            })
        )
    }
    if(variant == "normal"){
        return (
            <div>
                <label htmlFor={randForId} className="form-label">{label}</label>
                <select value={value === null ? '' : value} id={randForId} className={`${className} ${hasError}`} onChange={(e) => onChangeOption({target: {name: props.name, value: e.target.value}})} {...props} >
                    <option>Selecione uma opção</option>
                    {mapOptions()}
                </select>
                <ErrorDialog />
            </div>
        )
    }else if(variant == "simple-no-label"){
        return (
            <div>
                <select value={value === null ? '' : value} id={randForId} className={`${className} ${hasError}`} onChange={(e) => onChangeOption({target: {name: props.name, value: e.target.value}})} {...props} >
                    <option>Selecione uma opção</option>
                    {mapOptions()}
                </select>
                <ErrorDialog />
            </div>
        )
    }




   
}

export default Select;