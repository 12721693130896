import { useState, useRef, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Request from "../../api/Requests";
import { toast, ToastContainer } from 'react-toastify';
const Menu = ({ tab, handleLoading }) => {
    const navigate = useNavigate();
    let paramsUrl = useParams();

    const menuContainerRef = useRef(null);
    const menuListRef = useRef(null);
    // const [isOverflowing, setIsOverflowing] = useState(false);
    // useEffect(() => {
    const getName = () => {
        return localStorage.getItem('nightspot_webapp_user_name') || ''; // Pega o nome da localStorage, ou string vazia
    };

    const isPromoter = () => {
        const ispromoter = localStorage.getItem('nightspot_webapp_user_is_promoter') || 0;
        return ispromoter == 'true' ? true : false;
    }

    //     const checkOverflow = () => {
    //         if (menuListRef.current.scrollWidth > menuContainerRef.current.offsetWidth) {
    //             setIsOverflowing(true);
    //         } else {
    //             setIsOverflowing(false);
    //         }
    //     };

    //     // Verifica o overflow ao montar o componente e ao redimensionar a janela
    //     checkOverflow();
    //     window.addEventListener('resize', checkOverflow);

    //     // Cleanup do event listener ao desmontar o componente
    //     return () => {
    //         window.removeEventListener('resize', checkOverflow);
    //     };
    // }, []);

    // const handleScrollRight = () => {
    //     menuListRef.current.scrollBy({ left: 100, behavior: 'smooth' });
    // };


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h3>Olá {getName()}</h3>
                </div>
            </div>


            <div className="row">
                <div className="col-12 menu-container" ref={menuContainerRef}>
                    <ul className="menu-list" ref={menuListRef}>
                        <li onClick={() => navigate('/my/qrcode')} className={`${tab === 'qrcode' ? 'active' : null}`}><i className="fa-solid fa-qrcode"></i></li>
                        
                        {!isPromoter() ? null : <li onClick={() => navigate('/my/promoters-dashboard')} className={`${tab === 'promoters-dashboard' ? 'active' : null}`}><i className="fa-solid fa-tachometer-alt me-2"></i>Gestão Promotor</li>}
                        {/* <li onClick={() => setTab('tables')} className={`${tab === 'tables' ? 'active' : null}`}>Mesas Vip</li>
                    <li onClick={() => setTab('tickets')} className={`${tab === 'tickets' ? 'active' : null}`}>Bilhetes</li> */}
                    </ul>
                    {/* {isOverflowing && (
                                    <button className="scroll-right-btn" onClick={handleScrollRight}>
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                )} */}
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default Menu;