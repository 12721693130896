import React, { useState, useEffect, useContext } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import PrivateRoute from './components/Util/Routes/PrivateRoute';
import AdminRoute from './components/Util/Routes/AdminRoute';
import { AuthProvider, AuthContext } from './Context/AppContext';

import HomeScreen from './screens/Home/HomeScreen';
import LoginScreen from './screens/Login/LoginScreen';
// import DashboardScreen from './screens/My/Dashboard/DashboardScreen';
// NightSpots
// import DashboardNightspotsList from './screens/My/Nightspots/List/NightspotsLista';
// import DashboardNightspotsForm from './screens/My/Nightspots/Form/FormNightspot';
// Events
// import EventList from './screens/My/Events/list/EventsList';
// import EventForm from './screens/My/Events/form/EventForm';

// import PromotersList from './screens/My/Promoters/List/PromotersList'

import Page404 from './screens/Pages/Page404/Page404';
import SearchBox from './screens/Layout/Header/Search/SearchBox';
import Footer from './screens/Layout/Footer/Footer';

// Admin
// import VenuesApproval from './screens/My/Admin/Venues/VenuesApproval';
// import VenuesApprovalView from './screens/My/Admin/Venues/VenuesApprovalView';


import AppRequests from './AppRequests';

import ReactGA from "react-ga4";
// import UserSettingsModal from './screens/My/Settings/User/UserSettingsModal';
import SignUp from './screens/SignUp/SignUp';
import SignupActivation from './screens/SignUp/SignupActivation';
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import PasswordReset from './screens/ForgotPassword/PasswordReset';
import PrivacyScreen from './screens/Pages/Privacy/PrivacyScreen';

import TawkTo from 'tawkto-react'
import AboutUs from './screens/Pages/AboutUs/AboutUs';

import GuestListAccess from './screens/guestlist/access/GuestListAccess';
import GuestListAccessEvent from './screens/guestlist/access/GuestListAccessEvent';
import GuestlistAccessEventSuccess from './screens/guestlist/access/GuestlistAccessEventSuccess';
import SignupAppActivation from './screens/SignUp/SignupAppActivation';
import BackofficeSetPassword from './screens/Login/BackofficeSetPassword';
import DeleteAccount from './screens/DeleteAccount/DeleteAccount';

import TableEvent from './screens/guestlist/vipTables/TableEvent'
import TableEventTransaction from './screens/guestlist/vipTables/TableEventTransaction';
import TableTransactionRedirect from './screens/guestlist/vipTables/TableTransactionRedirect';
import AuthApp from './screens/AuthApp/AuthApp';
import ReservedArea from './screens/my/ReservedArea';
import Auth from './screens/Auth/Auth';
import Error from './screens/my/Error/Error';
import ScreenQrcode from './screens/my/Qrcode/ScreenQrcode';
import PromotersDash from './screens/my/PromotersDash/PromotersDash';
import EventManager from './screens/my/PromotersDash/EventManager/EventManager';

// import DashboardAdmin from './screens/My/Admin/Dashboard/DashboardAdmin';
// import EventGuestListEntries from './screens/My/Events/list/guestlistStats/listEntries/ListEntries';
// import ListaContactosCampanha from './screens/My/Nightspots/ListCampaignContact/ListaContactosCampanha';
// import CampaignsList from './screens/My/Campaigns/CampaignsList';

// import Plans from './screens/My/Billing/Plans/Plans';
// import UsersList from './screens/My/Admin/Users/UsersList';
// import EventListCards from './screens/My/Events/list/EventsListCards';
// import ListAccounts from './screens/My/Billing/BillingAccount/ListAccounts';
// import Account from './screens/My/Billing/BillingAccount/Account';
// import BillingCheckout from './screens/My/Billing/Checkout/BillingCheckout';
// import BillingManager from './screens/My/Billing/Manager/BillingManager';
// import BillingCheckoutFinish from './screens/My/Billing/Checkout/BillingCheckoutFinish';
// import BillingTransactions from './screens/My/Billing/Transactions/BillingTransactions';
// import BillingInvoices from './screens/My/Billing/Invoices/BillingInvoices';
// import BillingFaq from './screens/My/Billing/Faq/BillingFaq';
// import VenuesList from './screens/My/Admin/Venues/VenuesList';
// import VenueEventList from './screens/My/Admin/Venues/Events/VenueEventList';
// import PaymentsList from './screens/My/Admin/Payments/PaymentsList';
// import PaymentsDetails from './screens/My/Admin/Payments/PaymentsDetails';
// import VipTablesConfig from './screens/My/Nightspots/Form/VipTables/VipTablesConfig';
// import TablesDesigner from './screens/My/Nightspots/Form/VipTables/Designer/TablesDesigner';
// import VipTablesPaymentsDetails from './screens/My/Nightspots/Form/VipTables/Payments/VipTablesPaymentsDetails';
// import EventTables from './screens/My/Events/tables/EventTables';
// import ReservationList from './screens/My/Events/tables/ReservationList/ReservationList';
// import TransactionsList from './screens/My/Events/tables/TransactionsList/TransactionsList';
// import WithdrawalRequestsList from './screens/My/Admin/WithdrawalRequests/WithdrawalRequestsList';
// import WithdrawalDetails from './screens/My/Admin/WithdrawalRequests/WithdrawalDetails';


const TRACKING_ID = "G-K1SNFG0ZFX"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  //const { setUserInfo } = useContext(AuthContext);
  const [Page, setPage] = useState({
    mobileMenu: {
      isVisible: false
    },
    // screenViewFluid: [

    // ]
  })

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    document.title = 'Nightspot - Nightclubs, Bars, Pubs and Rooftops';
  }, []);


  useEffect(() => {

    var tawk = new TawkTo("643499d931ebfa0fe7f78d31", "1gtmnhugd")

    tawk.onStatusChange((status) => {
      // console.log(status)
    })

  }, [])

  useEffect(() => {
    setPage({
      ...Page,
      path: window.location.pathname
    })
  }, [window.location.pathname])


  const contentManager = () => {
    return (
      <div >
        {/* <div className="container-fluid "> */}
        <BrowserRouter>
          {/* {nav()} */}

          <Routes>
            <Route exact path="/" index element={<HomeScreen />} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/password-reset/:token" element={<PasswordReset />} />
            <Route exact path="/password-set-backoffice/:token" element={<BackofficeSetPassword />} />
            <Route exact path="/signup-activation/:token" element={<SignupActivation />} />
            <Route exact path="/signup-app-activation/:token" element={<SignupAppActivation />} />
            <Route exact path="/about" element={<AboutUs />} />
            <Route exact path="/privacy" element={<PrivacyScreen />} />
            <Route exact path="/terms" element={<PrivacyScreen />} />

            <Route exact path="/u/:slug" element={<GuestListAccess type="user" />} />
            <Route exact path="/u/:slug/:event" element={<GuestListAccessEvent type="user" />} />
            <Route exact path="/v/:slug" element={<GuestListAccess type="venue" />} />
            <Route exact path="/v/:slug/:event" element={<GuestListAccessEvent type="venue" />} />
            <Route exact path="/guestlist-complete" element={<GuestlistAccessEventSuccess />} />
            <Route exact path="/delete-account/:token" element={<DeleteAccount />} />

            <Route exact path="/u/:slug/:event/tables/:id" element={<TableEvent type="user" />} />
            <Route exact path="/v/:slug/:event/tables/:id" element={<TableEvent type="venue" />} />
            
            
            <Route exact path="/u/:slug/:event/tables/:id/transaction/:id_transaction" element={<TableEventTransaction type="user" />} />
            <Route exact path="/v/:slug/:event/tables/:id/transaction/:id_transaction" element={<TableEventTransaction type="venue" />} />
            <Route exact path="/t/:id_transaction" element={<TableTransactionRedirect  />} />

            {/* APP Actions */}

            <Route exact path="/auth-app-redirect" element={<AuthApp  />} />
            <Route exact path="/auth/:token_id" element={<Auth  />} />


            {/* Reserved Area */}
            <Route exact path="/my/" element={<ScreenQrcode  />} />
            <Route exact path="/my/qrcode" element={<ScreenQrcode  />} />
            <Route exact path="/my/promoters-dashboard" element={<PromotersDash  />} />
            <Route exact path="/my/promoters-dashboard/event/:id_events" element={<EventManager tab={'home'} />} />
            <Route exact path="/my/promoters-dashboard/event/:id_events/invite-guests" element={<EventManager tab={'invite-guests'} />} />
            <Route exact path="/my/error" element={<Error  />} />
            

            {/* <Route path="/my/dashboard" element={
              <PrivateRoute>
                <DashboardScreen />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots" element={
              <PrivateRoute>
                <DashboardNightspotsList />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/list" element={
              <PrivateRoute>
                <DashboardNightspotsList />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/create/:plan" element={
              <PrivateRoute>
                <DashboardNightspotsForm />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/update/:id" element={
              <PrivateRoute>
                <DashboardNightspotsForm />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/:id/contacts" element={
              <PrivateRoute>
                <ListaContactosCampanha />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/:id/tables" element={
              <PrivateRoute>
                <VipTablesConfig />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/:id/tables/payments/details" element={
              <PrivateRoute>
                <VipTablesPaymentsDetails />
              </PrivateRoute>
            } />
            <Route path="/my/nightspots/:id/tables/designer" element={
              <PrivateRoute>
                <TablesDesigner />
              </PrivateRoute>
            } /> */}


            {/* Events */}
            {/* <Route path="/my/events/" element={
              <PrivateRoute>
                <EventListCards />
              </PrivateRoute>
            } />

            <Route path="/my/events/create" element={
              <PrivateRoute>
                <EventForm action="create" />
              </PrivateRoute>
            } />

            <Route path="/my/events/update/:id" element={
              <PrivateRoute>
                <EventForm action="update" />
              </PrivateRoute>
            } />
            <Route path="/my/events/:id/guestlist/entries" element={
              <PrivateRoute>
                <EventGuestListEntries />
              </PrivateRoute>
            } />
            <Route path="/my/events/:id/tables" element={
              <PrivateRoute>
                <EventTables />
              </PrivateRoute>
            } />
            <Route path="/my/events/:id/tables/reservation-list" element={
              <PrivateRoute>
                <ReservationList />
              </PrivateRoute>
            } />
            <Route path="/my/events/:id/tables/transactions-list" element={
              <PrivateRoute>
                <TransactionsList />
              </PrivateRoute>
            } /> */}

            {/* Settings */}


            {/* Promoters */}
            {/* <Route path="/my/promoters" element={
              <PrivateRoute>
                <PromotersList />
              </PrivateRoute>
            } /> */}

            {/* campaigns */}
            {/* <Route path="/my/campaigns" element={
              <PrivateRoute>
                <CampaignsList />
              </PrivateRoute>
            } /> */}



            {/* Billing */}
            {/* <Route path="/my/billing/manager/:id_venues" element={
              <PrivateRoute>
                <BillingManager />
              </PrivateRoute>
            } />
            <Route path="/my/billing/accounts" element={
              <PrivateRoute>
                <ListAccounts />
              </PrivateRoute>
            } />
            <Route path="/my/billing/account" element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            } />
            <Route path="/my/billing/checkout/:id" element={
              <PrivateRoute>
                <BillingCheckout />
              </PrivateRoute>
            } />
            <Route path="/my/billing/checkout/:id/finish" element={
              <PrivateRoute>
                <BillingCheckoutFinish />
              </PrivateRoute>
            } />

            <Route path="/my/billing/transactions/:id_venues" element={
              <PrivateRoute>
                <BillingTransactions />
              </PrivateRoute>
            } />
            
            
            <Route path="/my/billing/invoices/:id_venues" element={
              <PrivateRoute>
                <BillingInvoices/>
              </PrivateRoute>
            } />
            
            <Route path="/my/billing/faq/:id_venues" element={
              <PrivateRoute>
                <BillingFaq/>
              </PrivateRoute>
            } /> */}

            
            








            {/* ADMIN ROUTES */}

            {/* <Route path="/my/admin/dashboard" element={
              <AdminRoute>
                <DashboardAdmin />
              </AdminRoute>
            } />

            <Route path="/my/admin/venues-approval" element={
              <AdminRoute>
                <VenuesApproval />
              </AdminRoute>
            } />
            
            <Route path="/my/admin/venues" element={
              <AdminRoute>
                <VenuesList />
              </AdminRoute>
            } />
            
            <Route path="/my/admin/venues/:id/events" element={
              <AdminRoute>
                <VenueEventList />
              </AdminRoute>
            } />
            
            <Route path="/my/admin/venues-approval/:id" element={
              <AdminRoute>
                <VenuesApprovalView />
              </AdminRoute>
            } />


            <Route path="/my/admin/users" element={
              <AdminRoute>
                <UsersList />
              </AdminRoute>
            } />
            <Route path="/my/admin/payments" element={
              <AdminRoute>
                <PaymentsList />
              </AdminRoute>
            } />
            <Route path="/my/admin/payments/:id/details" element={
              <AdminRoute>
                <PaymentsDetails />
              </AdminRoute>
            } />
            <Route path="/my/admin/withdrawal-request" element={
              <AdminRoute>
                <WithdrawalRequestsList />
              </AdminRoute>
            } />
            <Route path="/my/admin/withdrawal-request/:id/details" element={
              <AdminRoute>
                <WithdrawalDetails />
              </AdminRoute>
            } />
 */}


            {/* <Route path="/collection/:slug" element={<CollectionSingle />} />
              <Route path="/asset/:contract/:id" element={<AssetSingle />} /> */}
            <Route path="*" element={<Page404 />} />


          </Routes>
        </BrowserRouter>
        {/* </div> */}
        {/* <div className='container-fluid block-newsletter'>

        </div> */}
        {/* <UserSettingsModal /> */}
        {/* <Footer /> */}
      </div>
    );
  }



  return (
    <AuthProvider>
      {contentManager()}
    </AuthProvider>
  )
}
export default App;
