import { useEffect, useState } from "react";
import Request from "../../../api/Requests";
import Loading from "../../../components/Loading";
import Menu from "../Menu";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';

const PromotersDash = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState(null)

    useEffect(() => {
        getEvent()
    }, [])

    const getEvent = async () => {
        setIsLoading(true)
        const response = await Request('reserved-area/get-promoter-events', 'GET', {}, true);
        if (response && response.status) {
            setState(response.data)
        } else {

        }
        setIsLoading(false)
    }

    const EventsList = () => {

        const Event = ({event, index}) => {
            const styleLabelStats = {
                fontSize: '0.7rem',
                color: '#bfbfbf',
                textAlign: 'center'
            }
            return (
                <div
                    className={`row mb-4 event-item ${index % 2 === 0 ? 'event-bg-light' : 'event-bg-dark'}`}
                    key={event.id}
                    style={{ padding: 0 }}
                >
                    <div className="col-2 p-0">
                        <img src={event.image_url} alt={event.name}  className="img-fluid event-img" />
                    </div>
                    <div className="col-9">
                        <h5 className="text-light">{event.name}</h5>
                        <div className="mt-3" style={{ fontSize: '0.9rem' }}>
                            {event.date_formated}
                            <small className="mx-3" style={{ color: 'rgb(234, 44, 97)' }}>|</small>
                            <span style={{ color: '#939393' }}>{event.hour_start} - {event.hour_end}</span>
                        </div>
                        {/* <hr/> */}
                        <div className="row my-2">
                            <div className="col-4">
                                <div style={styleLabelStats}>Nº Registos</div>
                                <div className="text-center" style={{fontSize: '0.8rem'}}>{event.total_registers}</div>
                            </div>
                            <div className="col-4">
                                <div style={styleLabelStats}>Nº Entradas</div>
                                <div className="text-center" style={{fontSize: '0.8rem'}}>{event.total_entries}</div>
                            </div>
                            <div className="col-4">
                                <div style={styleLabelStats}>Conversão</div>
                                <div className="text-center" style={{fontSize: '0.8rem'}}>{event.tx_rate_convert}%</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 d-flex p-0 justify-content-end align-items-center">
                        <Link className="btn" style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', borderRadius: 0, borderLeft: '1px solid #5e5e5e'/*, backgroundColor: 'rgba(0,0,0,0.5)'*/ }} to={`/my/promoters-dashboard/event/${event.id}`}><i className="fa-solid fa-gears"></i></Link>
                    </div>
                </div>
            )
        }


        const NextEvents = () => {
            return (state && state.events && state.events.length == 0 ? 'Sem Eventos' : state.events.filter((k) => k.status == "ACTIVE").map((event, index) => (
                 <Event event={event} index={index}  />
            )))
        }
        
        
        const OldEvents = () => {
            return (state && state.events && state.events.length == 0 ? 'Sem Eventos' : state.events.filter((k) => k.status == "CLOSED").map((event, index) => (
                 <Event event={event} index={index}  />
            )))
        }

        return (
            <>
                <div className="row mt-3 ">
                    <div className="col-12 ">
                        <h3>Próximos Eventos</h3>
                    </div>
                </div>
                <div className="row mt-3 ">
                    <div className="col-12 ">
                        <NextEvents />
                    </div>
                </div>
                <div className="row mt-3 ">
                    <div className="col-12 ">
                        <h3>Eventos Terminados</h3>
                    </div>
                </div>
                <div className="row mt-3 ">
                    <div className="col-12 ">
                        <OldEvents />
                    </div>
                </div>
            </>
        )
    }

    

    const handleCopy = () => {
        const textToCopy = state.promoter.url; // Pega o texto que está na div
        navigator.clipboard.writeText(textToCopy)
            .then(() => {

                toast.success("Texto copiado para a área de transferência!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            })
            .catch(err => {
                console.error('Falha ao copiar o texto: ', err);
                toast.success('Falha ao copiar o texto: ' + err, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
    };

    return (isLoading ? <Loading /> :
        <div className="container">
            <div className="row mb-3 justify-content-center">
                <div className="col-12 col-sm-6">
                    <div className="container position-relative z-1">
                        <div className="box-tables mt-5">

                            <Menu tab="promoters-dashboard" />

                            <div className="row  mt-3">
                                <div className="col-12 ">
                                    <h3>Partilha o link único</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 ">
                                    <label className="form-label">Link</label>
                                    <div
                                        style={{
                                            backgroundColor: '#2c2c2c',
                                            borderRadius: '4px',
                                            padding: '10px',
                                            display: 'flex', // Flexbox para alinhar o conteúdo e o botão
                                            justifyContent: 'space-between', // Espaço entre o texto e o botão
                                            alignItems: 'center', // Alinha verticalmente no centro
                                        }}
                                    >
                                        <span style={{ color: '#fff' }}>{state.promoter.url}</span>
                                        <button
                                            onClick={handleCopy}
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                color: '#fff',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                            }}
                                        >
                                            <i className="fas fa-copy"></i>
                                        </button>
                                        {/* <a
                                            href={state.promoter.url}
                                            target="_blank"
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                color: '#fff',
                                                cursor: 'pointer',
                                                fontSize: '16px',
                                            }}
                                        >
                                            <i className="fas fa-external-link-alt"></i>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <EventsList />


                        </div>
                    </div>
                </div>
            </div>
        </div >

    )
}

export default PromotersDash;