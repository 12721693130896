import React, { useState, forwardRef } from "react";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid


//const GridView = ({ ref = null, rows = [], cols = [], rowSelection = "single"}) => {
const GridView = forwardRef(({
    rows = [],
    cols = [],
    rowSelection = "single",
    pagination = true,
    height = "70vh",
    statusBar= {}
}, ref) => {
    // Row Data: The data to be displayed.
    //const [rowData, setRowData] = useState(rows);

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState(cols);

    const [defaultColDef, setDefaultColDef] = useState({
        filter: true,
        floatingFilter: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        suppressFloatingFilterButton: true,
        floatingFilterComponentParams: {},
        // floatingFilterComponentParams: { suppressFloatingFilterButton: true }
    });

    const [autoSizeStrategy, setAutoSizeStrategy] = useState({
        type: "fitGridWidth",
        defaultMinWidth: 100,
    });



    return (
        <div className="ag-theme-quartz-dark mt-4" style={{ width: "100%", height: height }}>
            <AgGridReact
                ref={ref}
                //rowData={rowData}
                rowData={rows}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                pagination={pagination}
                paginationAutoPageSize={true}
                rowSelection={rowSelection}
                autoSizeStrategy={autoSizeStrategy}
                statusBar={statusBar}
                
            />
        </div>
    )
})

export default GridView;