import { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import { useNavigate, Link, useParams } from "react-router-dom";
import AuthRequests from "./AuthRequests";

const Auth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const urlParams = useParams();
    const navigate = useNavigate();

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    function setCookie(name, value, days) {
        let expires = "";
        
        // Define a data de expiração (opcional)
        if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toUTCString();
        }
        
        // Definir o cookie
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
      }

    const checkLogin = async () => {
        const token = getCookie("token-website")
        let response = await AuthRequests.checkLogin({...urlParams});
        if (response && response.status == true) {
            setCookie("token-website", urlParams.token_id, 90);
            navigate("/my");
        }
    }

    useEffect(() => {
        checkLogin();
    }, [])

    return isLoading ? <Loading /> : null;
}

export default Auth;